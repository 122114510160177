//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import React from 'react';

import classNames    from 'classnames';
import I18n          from 'i18next';
import isTouchDevice from 'is-touch-device';
import _             from 'lodash';

import DataProvider from '@helper/DataProvider';

import styles    from './styles.module.scss';
import Icon      from '../Icon';
import IconType  from '../Icon/IconType';
import PropTypes from '../PropTypes';

const Component = ({
    dataSheetButtonPressed              = _.noop,
    hideIconForEmptyValue               = false,
    onProductImagePreviewIconMouseHover = _.noop,
    onProductImagePreviewIconMouseLeave = _.noop,
    partNumber                          = [],
}) => {
    const productData = DataProvider.getById(partNumber);
    let description   = partNumber;

    if (productData) {
        description = productData[I18n.t('descriptionKey')];
    }

    const onProductImagePreviewIconClick           = () => {
        if (
            isTouchDevice() &&
            onProductImagePreviewIconMouseHover
        ) {
            onProductImagePreviewIconMouseHover();
        }
    };
    const innerOnProductImagePreviewIconMouseHover = () => {
        if (!isTouchDevice() && onProductImagePreviewIconMouseHover) {
            onProductImagePreviewIconMouseHover();
        }
    };
    const innerOnProductImagePreviewIconMouseLeave = () => {
        if (!isTouchDevice() && onProductImagePreviewIconMouseLeave) {
            onProductImagePreviewIconMouseLeave();
        }
    };
    const renderDataSheetIcon                      = () => {
        const hasFiles = productData && productData.files;

        return (
            <span
                className={classNames(styles.dataSheetIconWrapper, {
                    [styles.dataSheetIconWrapperNoFiles]: !hasFiles,
                })}
                onClick={dataSheetButtonPressed}
                title={I18n.t('downloads')}
            >
                <Icon iconType={IconType.download} />
            </span>
        );
    };
    const renderProductImagePreviewIcon            = () => (
        <span
            className={styles.productImagePreviewIconWrapper}
            onClick={onProductImagePreviewIconClick}
            onMouseEnter={innerOnProductImagePreviewIconMouseHover}
            onMouseLeave={innerOnProductImagePreviewIconMouseLeave}
            title={I18n.t('productImagePreview')}
        >
            <Icon iconType={IconType.search} />
        </span>
    );

    return (
        <span
            className={styles.label}
            title={description}
        >
            {renderDataSheetIcon()}
            {renderProductImagePreviewIcon()}
            {partNumber}
        </span>
    );
};

Component.propTypes = {
    dataSheetButtonPressed:              PropTypes.func,
    hideIconForEmptyValue:               PropTypes.bool,
    onProductImagePreviewIconMouseHover: PropTypes.func,
    onProductImagePreviewIconMouseLeave: PropTypes.func,
    partNumber:                          PropTypes.any,
};

export default Component;
