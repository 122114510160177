//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import _ from 'lodash';

import AccessoryTypes             from '@constants/AccessoryTypes';
import Limitations                from '@constants/Limitations';
import ProductCategoryType        from '@constants/ProductCategoryType';
import ProductSlotTypes           from '@constants/ProductSlotType';
import ProductSubCategoryType     from '@constants/ProductSubCategoryType';
import SlotType                   from '@constants/SlotType';
import YesNoOptional              from '@constants/YesNoOptional';
import StringHelper               from '@helper/String';
import LimitationsCalculator      from '@store/calculators/limitations';
import PowerConsumptionCalculator from '@store/calculators/powerConsumption';

import { isKVMTecEnabled }                     from './Configuration';
import { getReverseLayoutIndicatorCharacters } from './Configuration';
import { getReverseLayoutIndicatorPosition }   from './Configuration';
import LimitationsApplier                      from './Limitations';
import ihseProductData                         from '../data/dataPrecompiled';
import productImages                           from '../data/dataPrecompiledImage';

class DataProvider {
    static idCache = {};

    static defaultProductTypeRejectList = [
        AccessoryTypes.cover,
    ];

    static emptyProductTypeRejectList = [];

    static buildIdCache(data) {
        if (_.isArray(data)) {
            for (const productData of data) {
                if (!this.idCache[productData.partNo]) {
                    this.idCache[productData.partNo] = productData;
                }
            }
        } else if (_.isObject(data)) {
            const values = Object.values(data);

            for (const value of values) {
                this.buildIdCache(value);
            }
        }
    }

    static getCleanedData(data, rejectProductTypes = DataProvider.defaultProductTypeRejectList) {
        const cleaned = _.filter(data, (productData) => {
            if (productData.internal) {
                return false;
            }

            const rejectListContainsType = rejectProductTypes.includes(productData.type);

            return !rejectListContainsType;
        });

        return cleaned;
    }

    static getCompatibleProducts(productsToProcess, endOfLifeProductsVisible, checkMoreThanOnlyEndOfLife, checkMaritime, selectedProduct, selectedSlot, rejectIncompatible) {
        const selectedProductProductData = _.get(selectedProduct, 'productData');
        const selectedSlotProductData    = _.get(selectedSlot, 'productData');
        const selectedProductId          = _.get(selectedProductProductData, 'id');
        const selectedSlotProductId      = _.get(selectedSlotProductData, 'id');
        const slotCount                  = _.get(selectedProduct, 'subProducts.slot.length', 0);
        const innerProductsToProcess     = (
            productsToProcess
                ? [...productsToProcess]
                : []
        );

        console.log(
            'DataProvider: getCompatibleProducts',
            {
                innerProductsToProcess,
                endOfLifeProductsVisible,
                checkMoreThanOnlyEndOfLife,
                checkMaritime,
                selectedProduct,
                selectedSlot,
                rejectIncompatible,
                selectedProductProductData,
                selectedSlotProductData,
                selectedProductId,
                selectedSlotProductId,
                slotCount,
            },
        );

        let compatibleProducts = [...innerProductsToProcess];

        if (!endOfLifeProductsVisible) {
            compatibleProducts = _.reject(compatibleProducts, DataProvider.getProductIsEndOfLife);
        }

        if (!selectedProductProductData) {
            return compatibleProducts;
        }

        if (checkMoreThanOnlyEndOfLife) {
            const configurableSfpCount = _.get(selectedSlotProductData, 'configurableSfpCount');
            let filteredData           = [];

            if (configurableSfpCount > 0) {
                const filteredItemsBySelectedSlotId = LimitationsApplier.filterProductListByLimitTo(
                    innerProductsToProcess,
                    selectedSlotProductId,
                    true,
                );
                filteredData                        = filteredData.concat(filteredItemsBySelectedSlotId);
                filteredData                        = _.uniqBy(filteredData, 'partNo');
            }

            if (selectedProductId) {
                const filteredItemsBySelectedProductId = LimitationsApplier.filterProductListByLimitTo(
                    innerProductsToProcess,
                    selectedProductId,
                );
                filteredData                           = filteredData.concat(filteredItemsBySelectedProductId);
            }

            const chassisMaritime = _.get(selectedProductProductData, 'maritime', YesNoOptional.no);

            if (checkMaritime) {
                if (chassisMaritime === YesNoOptional.yes) {
                    compatibleProducts = _.filter(
                        compatibleProducts,
                        (item) => {
                            const maritime  = _.get(item, 'maritime', YesNoOptional.no);
                            const isAllowed = [
                                YesNoOptional.yes,
                                YesNoOptional.optional,
                            ].includes(maritime);

                            return isAllowed;
                        },
                    );
                } else if (chassisMaritime === YesNoOptional.no) {
                    compatibleProducts = _.filter(
                        compatibleProducts,
                        (item) => {
                            const maritime  = _.get(item, 'maritime', YesNoOptional.no);
                            const isAllowed = [
                                YesNoOptional.no,
                                YesNoOptional.optional,
                            ].includes(maritime);

                            return isAllowed;
                        },
                    );
                }
            }

            const incompatibleData = _.differenceBy(compatibleProducts, filteredData, 'partNo')
                .map((entry) => (
                    {
                        ...entry,
                        isIncompatible: true,
                    }
                ));
            const compatibleData   = _.differenceBy(compatibleProducts, incompatibleData, 'partNo')
                .map((entry) => (
                    {
                        ...entry,
                        isIncompatible: false,
                    }
                ));
            let data               = compatibleData;

            if (!rejectIncompatible) {
                data = data.concat(incompatibleData);
            }

            data = _.map(data, (entry) => {
                const productSlotType  = entry.productSlotType;
                const allowedSlotTypes = [
                    ProductSlotTypes.ioBoard,
                    ProductSlotTypes.equipment,
                    ProductSlotTypes.addOnModule,
                    ProductSlotTypes.cpuBoard,
                    ProductSlotTypes.mainboard,
                    ProductSlotTypes.otherModule,
                    ProductSlotTypes.usb20Module,
                ];

                if (!allowedSlotTypes.includes(productSlotType)) {
                    return entry;
                }

                let hitLimitation = true;

                for (let index = 0; index < slotCount; index++) {
                    const futureSelectedProduct    = _.cloneDeep(selectedProduct);
                    const futureSelectedSubProduct = futureSelectedProduct.subProducts[SlotType.slot][index];

                    if (futureSelectedSubProduct) {
                        futureSelectedSubProduct.productData = entry;
                    }

                    futureSelectedProduct.powerConsumptionInMilliAmps = PowerConsumptionCalculator.getPowerConsumption(
                        futureSelectedProduct,
                        futureSelectedProduct.subProducts,
                    );

                    const slotIsValid = LimitationsCalculator.slotIsValid(
                        futureSelectedProduct,
                        futureSelectedSubProduct,
                        futureSelectedProduct.powerConsumptionInMilliAmps,
                        SlotType.slot,
                        index,
                    );

                    if (slotIsValid) {
                        hitLimitation = false;

                        break;
                    }
                }

                return {
                    ...entry,
                    hitLimitation,
                };
            });

            if (rejectIncompatible) {
                data = _.reject(data, DataProvider.getProductHasHitLimitation);
            }

            console.log('DataProvider: getCompatibleProducts: data', data);
            console.log('DataProvider: getCompatibleProducts: filteredData', filteredData);

            return data;
        }

        console.log('DataProvider: getCompatibleProducts: compatibleProducts', compatibleProducts);

        return compatibleProducts;
    }

    static getProductIsEndOfLife(product) {
        return product.isEndOfLife;
    }

    static getProductHasHitLimitation(product) {
        return product.hitLimitation;
    }

    // TODO: könnte man das auch cachen oder auslagern?
    static getFanForProductId(productId) {
        const products = ihseProductData.extender.chassisAccessories;

        for (const product of products) {
            if (product.type === AccessoryTypes.fan) {
                for (const limitation of product.limitations) {
                    if (
                        limitation.type === Limitations.limitTo &&
                        limitation.partNo === productId
                    ) {
                        return product;
                    }
                }
            }
        }

        return null;
    }

    static getFileTypeUrl(fileType) {
        return ihseProductData.fileTypeUrls[fileType];
    }

    static getImageForProductId(productId, suffix) {
        const key = productId + suffix;

        if (productImages[key]) {
            return productImages[key];
        }

        return null;
    }

    static getById(id) {
        return DataProvider.idCache[id];
    }

    static getReversedById(id) {
        const reverseIndicators        = getReverseLayoutIndicatorCharacters();
        const reverseIndicatorPosition = getReverseLayoutIndicatorPosition();
        const reverseIndicatorIndex    = reverseIndicatorPosition - 1;
        const fallbackId               = DataProvider.idCache[id];

        if (_.size(id) < reverseIndicatorPosition) {
            return fallbackId;
        }

        if (id[reverseIndicatorIndex] === reverseIndicators.remote) {
            const reversedId = StringHelper.replaceOnIndex(id, reverseIndicatorIndex, reverseIndicators.local);

            return DataProvider.idCache[reversedId];
        }

        if (id[reverseIndicatorIndex] === reverseIndicators.local) {
            const reversedId = StringHelper.replaceOnIndex(id, reverseIndicatorIndex, reverseIndicators.remote);

            return DataProvider.idCache[reversedId];
        }

        return fallbackId;
    }

    static getEquipmentProducts() {
        return ihseProductData.equipment;
    }

    static getExtenderProducts(rejectProductTypes = DataProvider.defaultProductTypeRejectList) {
        const addOnModules               = _.get(ihseProductData, 'extender.addOnModules', []);
        const chassis                    = _.get(ihseProductData, 'extender.chassis', []);
        const chassisAccessories         = _.get(ihseProductData, 'extender.chassisAccessories', []);
        const mainboards                 = _.get(ihseProductData, 'extender.mainboards', []);
        const otherModules               = _.get(ihseProductData, 'extender.otherModules', []);
        const compactExtenders           = _.get(ihseProductData, 'extender.compactExtenders', []);
        const secureExtenders            = _.get(ihseProductData, 'extender.secureExtenders', []);
        const miscExtenders              = _.get(ihseProductData, 'extender.miscExtenders', []);
        const compactExtenderAccessories = _.get(ihseProductData, 'extender.compactExtenderAccessories', []);
        const usb20Modules               = _.get(ihseProductData, 'extender.usb20Modules', []);

        return DataProvider.getCleanedData(
            _.concat(
                addOnModules,
                chassis,
                chassisAccessories,
                mainboards,
                otherModules,
                compactExtenders,
                secureExtenders,
                miscExtenders,
                compactExtenderAccessories,
                usb20Modules,
            ),
            rejectProductTypes,
        );
    }

    static getExtenderAccessoryProducts() {
        return DataProvider.getCleanedData([
            ...ihseProductData.extender.chassisAccessories,
        ]);
    }

    static getExtenderSlotProducts(filterIncompatibleProducts = false) {
        const products = DataProvider.getCleanedData([
            ...ihseProductData.extender.addOnModules,
            ...ihseProductData.extender.mainboards,
            ...ihseProductData.extender.otherModules,
            ...ihseProductData.extender.usb20Modules,
        ]);

        if (filterIncompatibleProducts) {
            const filteredProducts = _.reject(products, (product) => {
                return product.isIncompatible;
            });

            return filteredProducts;
        }

        return products;
    }

    static staticGetProductsForProductCategoryType(
        productCategoryType,
        rejectProductTypes = DataProvider.defaultProductTypeRejectList,
    ) {
        switch (productCategoryType) {
            // @formatter:off
            case ProductCategoryType.equipment: return this.getEquipmentProducts();
            case ProductCategoryType.extender:  return this.getExtenderProducts(rejectProductTypes);
            case ProductCategoryType.matrix:    return this.getMatrixProducts();
            case ProductCategoryType.fullIp:    return this.getFullIpProducts();
            // @formatter:on
        }
    }

    static staticGetSlotProductsForProductCategoryType(productCategoryType, productSubCategoryType) {
        switch (productCategoryType) {
            // @formatter:off
            case ProductCategoryType.extender:  return this.getExtenderSlotProducts();
            case ProductCategoryType.matrix:    return this.getMatrixSlotProducts();
            case ProductCategoryType.fullIp:    return this.getFullIpSlotProducts(productSubCategoryType);
            // @formatter:on
        }
    }

    static staticGetSlotProductsForProductCategoryTypeAndSlotType(productCategoryType, slotTye, productSubCategoryType) {
        switch (slotTye) {
            case SlotType.slot:
                if (
                    productSubCategoryType &&
                    productCategoryType === ProductCategoryType.matrix &&
                    productSubCategoryType === ProductSubCategoryType.flex
                ) {
                    return DataProvider.getMatrixFlexFrontPlates();
                }

                return DataProvider.staticGetSlotProductsForProductCategoryType(productCategoryType, productSubCategoryType);

            case SlotType.accessory:
            case SlotType.fan:
            case SlotType.powerSupplyUnit:
                return DataProvider.staticGetAccessoryProductsForProductCategoryType(productCategoryType, productSubCategoryType);
        }

        return [];
    }

    static staticGetAccessoryProductsForProductCategoryType(productCategoryType, productSubCategoryType) {
        switch (productCategoryType) {
            // @formatter:off
            case ProductCategoryType.extender:  return this.getExtenderAccessoryProducts();
            case ProductCategoryType.matrix:    return this.getMatrixAccessoryProducts();
            case ProductCategoryType.fullIp:    return this.getFullIpAccessoryProducts(productSubCategoryType);
            // @formatter:on
        }
    }

    static getMatrixProducts(rejectProductTypes = DataProvider.defaultProductTypeRejectList) {
        return DataProvider.getCleanedData(
            [
                ...ihseProductData.matrix.enterprise.chassis,
                ...ihseProductData.matrix.enterprise.ioBoards,
                ...ihseProductData.matrix.enterprise.cpuBoards,
                ...ihseProductData.matrix.enterprise.accessories,
                ...ihseProductData.matrix.compact.accessories,
                ...ihseProductData.matrix.compact.matrix,
                ...ihseProductData.matrix.flex.accessories,
                ...ihseProductData.matrix.flex.frontPlates,
                ...ihseProductData.matrix.flex.ioBoards,
                ...ihseProductData.matrix.flex.matrix,
            ],
            rejectProductTypes,
        );
    }

    static getFullIpProducts(rejectProductTypes = DataProvider.defaultProductTypeRejectList) {
        if (!isKVMTecEnabled()) {
            return [];
        }

        return DataProvider.getCleanedData(
            [
                ...ihseProductData.fullIp.resolutionFullHd.usb,
                ...ihseProductData.fullIp.resolutionFullHd.accessories,
                ...ihseProductData.fullIp.resolutionFullHd.matrixManagement,
                ...ihseProductData.fullIp.resolutionFullHd.flexline,
                ...ihseProductData.fullIp.resolutionFullHd.easyline,
                ...ihseProductData.fullIp.resolution4K.scalableline,
                ...ihseProductData.fullIp.resolution4K.media4Connect,
            ],
            rejectProductTypes,
        );
    }

    static getMatrixAccessoryProducts() {
        return DataProvider.getCleanedData([
            ...ihseProductData.matrix.enterprise.accessories,
        ]);
    }

    static getFullIpAccessoryProducts(productSubCategoryType, filterIncompatibleProducts = false) {
        let products = [];

        switch (productSubCategoryType) {
            case ProductSubCategoryType.fullIp4K:
                products = DataProvider.getCleanedData([
                    ...ihseProductData.fullIp.resolution4K.accessories,
                ]);

                break;
            case ProductSubCategoryType.fullIpFullHd:
                products = DataProvider.getCleanedData([
                    ...ihseProductData.fullIp.resolutionFullHd.accessories,
                ]);

                break;
        }

        if (filterIncompatibleProducts) {
            products = _.reject(products, (product) => product.isIncompatible);
        }

        return products;
    }

    static getMatrixSlotProducts(filterIncompatibleProducts = false) {
        const products = DataProvider.getCleanedData([
            ...ihseProductData.matrix.enterprise.ioBoards,
            ...ihseProductData.matrix.enterprise.cpuBoards,
            ...ihseProductData.matrix.flex.frontPlates,
        ]);

        if (filterIncompatibleProducts) {
            const filteredProducts = _.reject(products, (product) => product.isIncompatible);

            return filteredProducts;
        }

        return products;
    }

    static getFullIpSlotProducts(productSubCategoryType, filterIncompatibleProducts = false) {
        if (!isKVMTecEnabled()) {
            return [];
        }

        let products = [];

        if (productSubCategoryType === ProductSubCategoryType.fullIp4K) {
            products = DataProvider.getCleanedData([
                ...ihseProductData.fullIp.resolution4K.accessories,
                ...ihseProductData.fullIp.resolution4K.matrixManagement,
            ]);
        } else if (productSubCategoryType === ProductSubCategoryType.fullIpFullHd) {
            products = DataProvider.getCleanedData([
                ...ihseProductData.fullIp.resolutionFullHd.accessories,
                ...ihseProductData.fullIp.resolutionFullHd.matrixManagement,
            ]);
        }

        products = _.reject(products, (product) => product.type !== AccessoryTypes.option);
        products = _.reject(products, (product) => {
            const { limitations } = product;

            if (
                !limitations ||
                limitations.length === 0
            ) {
                return true;
            }

            const onlyContainsEndOfLifeLimitation = (
                limitations.length === 1 &&
                limitations[0].type === Limitations.endOfLife
            );

            return onlyContainsEndOfLifeLimitation;
        });

        if (filterIncompatibleProducts) {
            const filteredProducts = _.reject(products, (product) => product.isIncompatible);

            return filteredProducts;
        }

        return products;
    }

    static getExtenderAddOnModules() {
        return DataProvider.getCleanedData(ihseProductData.extender.addOnModules);
    }

    static getExtenderChassis() {
        return DataProvider.getCleanedData(ihseProductData.extender.chassis);
    }

    static getMatrixEnterpriseChassis() {
        return DataProvider.getCleanedData(ihseProductData.matrix.enterprise.chassis);
    }

    static getMatrixEnterpriseIoBoards() {
        return DataProvider.getCleanedData(ihseProductData.matrix.enterprise.ioBoards);
    }

    static getMatrixEnterpriseCpuBoards() {
        return DataProvider.getCleanedData(ihseProductData.matrix.enterprise.cpuBoards);
    }

    static getMatrixEnterpriseAccessories() {
        return DataProvider.getCleanedData(ihseProductData.matrix.enterprise.accessories);
    }

    static getMatrixCompactMatrix() {
        return DataProvider.getCleanedData(ihseProductData.matrix.compact.matrix);
    }

    static getMatrixFlexMatrix() {
        return DataProvider.getCleanedData(ihseProductData.matrix.flex.matrix);
    }

    static getMatrixFlexMatrixConfigurable() {
        const matrixFlexConfigurable = _.filter(
            ihseProductData.matrix.flex.matrix,
            {
                configurable: true,
            },
        );

        return matrixFlexConfigurable;
    }

    static getMatrixFlexMatrixPreconfigured() {
        const matrixFlexConfigurable = _.filter(
            ihseProductData.matrix.flex.matrix,
            {
                configurable: false,
            },
        );

        return matrixFlexConfigurable;
    }

    static getMatrixFlexAccessories() {
        return DataProvider.getCleanedData(ihseProductData.matrix.flex.accessories);
    }

    static getMatrixFlexIoBoards() {
        return DataProvider.getCleanedData(ihseProductData.matrix.flex.ioBoards);
    }

    static getMatrixFlexFrontPlates() {
        return DataProvider.getCleanedData(ihseProductData.matrix.flex.frontPlates);
    }

    static getMatrixCompactAccessories() {
        return DataProvider.getCleanedData(ihseProductData.matrix.compact.accessories);
    }

    static getExtenderChassisAccessories(rejectProductTypes = DataProvider.defaultProductTypeRejectList) {
        return DataProvider.getCleanedData(ihseProductData.extender.chassisAccessories, rejectProductTypes);
    }

    static getExtenderMainboards() {
        return DataProvider.getCleanedData(ihseProductData.extender.mainboards);
    }

    static getExtenderOtherModules() {
        return DataProvider.getCleanedData(ihseProductData.extender.otherModules);
    }

    static getExtenderCompact() {
        return DataProvider.getCleanedData(ihseProductData.extender.compactExtenders);
    }

    static getExtenderMisc() {
        return DataProvider.getCleanedData(ihseProductData.extender.miscExtenders);
    }

    static getExtenderSecure() {
        return DataProvider.getCleanedData(ihseProductData.extender.secureExtenders);
    }

    static getExtenderCompactAccessories() {
        return DataProvider.getCleanedData(ihseProductData.extender.compactExtenderAccessories);
    }

    static getExtenderUsb20Modules() {
        return DataProvider.getCleanedData(ihseProductData.extender.usb20Modules);
    }

    static getEquipment(rejectProductTypes = DataProvider.defaultProductTypeRejectList) {
        return DataProvider.getCleanedData(ihseProductData.equipment, rejectProductTypes);
    }

    static getFullIpFullHdEasyLine() {
        return DataProvider.getCleanedData(ihseProductData.fullIp.resolutionFullHd.easyline);
    }

    static getFullIpFullHdFlexLine() {
        return DataProvider.getCleanedData(ihseProductData.fullIp.resolutionFullHd.flexline);
    }

    static getFullIpFullHdUsb() {
        return DataProvider.getCleanedData(ihseProductData.fullIp.resolutionFullHd.usb);
    }

    static getFullIpFullHdAccessories() {
        return DataProvider.getCleanedData(ihseProductData.fullIp.resolutionFullHd.accessories);
    }

    static getFullIpFullHdMatrixManagement() {
        return DataProvider.getCleanedData(ihseProductData.fullIp.resolutionFullHd.matrixManagement);
    }

    static getFullIp4KMedia4Connect() {
        return DataProvider.getCleanedData(ihseProductData.fullIp.resolution4K.media4Connect);
    }

    static getFullIp4KScalableline() {
        return DataProvider.getCleanedData(ihseProductData.fullIp.resolution4K.scalableline);
    }

    static getFullIp4KAccessories() {
        return DataProvider.getCleanedData(ihseProductData.fullIp.resolution4K.accessories);
    }

    static getFullIp4KMatrixManagement() {
        return DataProvider.getCleanedData(ihseProductData.fullIp.resolution4K.matrixManagement);
    }

    static getAll(rejectProductTypes = DataProvider.defaultProductTypeRejectList) {
        const allProducts = [].concat(
            DataProvider.getExtenderProducts(rejectProductTypes),
            DataProvider.getMatrixProducts(),
            DataProvider.getEquipment(),
            DataProvider.getFullIpProducts(),
        );

        return allProducts;
    }

    static getAllNoEndOfLife(rejectProductTypes = DataProvider.defaultProductTypeRejectList) {
        const allProducts                 = DataProvider.getAll(rejectProductTypes);
        const allProductsWithoutEndOfLife = _.reject(allProducts, DataProvider.getProductIsEndOfLife);

        return allProductsWithoutEndOfLife;
    }
}

DataProvider.buildIdCache(ihseProductData);

export default DataProvider;
