//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
// 
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
// 
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import React from 'react';

import I18n                   from 'i18next';
import _                      from 'lodash';
import { withTranslation }    from 'react-i18next';
import { connect }            from 'react-redux';
import { bindActionCreators } from 'redux';

import Overlays                 from '@constants/Overlays';
import ProductCategoryType      from '@constants/ProductCategoryType';
import DataProvider             from '@helper/DataProvider';
import ProductTitleHelper       from '@helper/ProductTitle';
import SelectionHelper          from '@helper/SelectionHelper';
import StateHelper              from '@helper/State';
import { ActiveProjectActions } from '@slices/activeProject';

import Overlay      from '../Overlay';
import OverlayType  from '../Overlay/OverlayType';
import TextHeadline from '../TextHeadline';

const Component = ({
    reversedProduct,
    reversedProductMeta,
    selectedProduct,
    closeButtonPressed,
    selectProduct,
}) => {
    const getTitle = () => {
        return SelectionHelper.getTranslation(
            _.get(reversedProduct, 'categoryType', null),
            {
                [ProductCategoryType.equipment]: 'reversedEquipmentTitle',
                [ProductCategoryType.extender]:  'reversedExtenderTitle',
                [ProductCategoryType.matrix]:    'reversedMatrixSwitchTitle',
            },
            'unknownError',
        );
    };
    const okButtonPressed = () => {
        selectProduct({
            categoryType: reversedProductMeta.categoryType,
            index:        reversedProductMeta.index,
            openDetails:  true,
        });
    };
    const getText = () => {
        if (reversedProduct) {
            switch (reversedProduct.categoryType) {
                case ProductCategoryType.extender: {
                    const productData = DataProvider.getById(selectedProduct.productData.id);
                    const productName = ProductTitleHelper.getProductTitle(
                        productData,
                        selectedProduct.customName,
                    );

                    return I18n.t('reversedExtenderText', {
                        name: productName,
                    });
                }
            }
        }

        return I18n.t('unknownError');
    };

    return (
        <Overlay
            allowDontShowAgain={true}
            cancelButtonTextKey={'no'}
            closeButtonPressed={closeButtonPressed}
            id={Overlays.switchToReversedProduct}
            okButtonPressed={okButtonPressed}
            okButtonTextKey={'yes'}
            overlayType={OverlayType.prompt}
            title={getTitle()}
        >
            <TextHeadline text={getText()} />
        </Overlay>
    );
};

const mapStateToProps = (state) => (
    {
        reversedProduct:     StateHelper.getSelectedProduct(state, state.activeProject.reversedProduct),
        reversedProductMeta: state.activeProject.reversedProduct,
        selectedProduct:     StateHelper.getSelectedProduct(state, state.activeProject.selectedProduct),
    }
);

const mapDispatchToProps = (dispatch) => bindActionCreators(ActiveProjectActions, dispatch);

Component.propTypes = {};

Component.defaultProps = {};

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(withTranslation()(Component));
