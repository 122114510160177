//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import I18n from 'i18next';
import _    from 'lodash';

import Limitations         from '@constants/Limitations';
import ProductCategoryType from '@constants/ProductCategoryType';
import SlotType            from '@constants/SlotType';
import WarningType         from '@constants/WarningType';
import LimitationsApplier  from '@helper/Limitations';

import ActiveProjectsFactory from '../factories/activeProjects';

class limitationRequiresHostModule {
    static updateProductWarnings = (
        limitation,
        state,
        changeSet,
        product,
        productWarnings,
        powerConsumptionInMilliamps,
        categoryType,
        productIndex,
    ) => {
        return false;
    };

    static updateSubProductWarnings = (
        limitation,
        state,
        changeSet,
        product,
        subProduct,
        subProductIndex,
        productWarnings,
        subProductWarnings,
        subProductCategory,
        powerConsumptionInMilliamps,
        categoryType,
        productIndex,
    ) => {
        const previousSubProductIndex = subProductIndex - 1;

        if (previousSubProductIndex < 0) {
            subProductWarnings.push(ActiveProjectsFactory.getWarning(
                WarningType.error,
                I18n.t('limitationRequiresHostModule1'),
            ));

            return true;
        }

        const previousSubProduct         = product.subProducts[SlotType.slot][previousSubProductIndex];
        const previousProductProductData = previousSubProduct.productData;

        if (
            !previousProductProductData ||
            _.isUndefined(previousProductProductData.supportsPiggyBack) ||
            (
                _.get(product, 'categoryType') === ProductCategoryType.extender &&
                _.get(product, 'productData.layoutDefinition.chassisLayout') === 'horizontal' &&
                // Check whether the addon card is in the same row as the mainboard, to allow this:
                //
                // |   addon   |           |           |           |
                // | mainboard |           |           |           |
                //
                // but not this:
                //
                // | mainboard |           |           |           |
                // |           |   addon   |           |           |
                //
                // in horizontal chassis. For vertical chassis we don't have to care since they
                // have no "row breaks"
                subProductIndex % 2 === 0
            )
        ) {
            subProductWarnings.push(ActiveProjectsFactory.getWarning(
                WarningType.error,
                I18n.t('limitationRequiresHostModule2'),
            ));

            return true;
        }

        if (!previousProductProductData.supportsPiggyBack) {
            subProductWarnings.push(ActiveProjectsFactory.getWarning(
                WarningType.error,
                I18n.t('limitationRequiresHostModule3'),
            ));

            return true;
        }

        let hasSpecificAddOnBindings = false;

        if (previousProductProductData.limitations) {
            for (const previousProductLimitation of previousProductProductData.limitations) {
                if (previousProductLimitation.type === Limitations.limitToAddOn) {
                    hasSpecificAddOnBindings = true;

                    const allowedPartNumbers = previousProductLimitation.partNo.split(',');

                    if (allowedPartNumbers.indexOf(subProduct.productData.partNo)) {
                        return true;
                    }

                    break;
                }
            }
        }

        const additionalPermittedAddons = LimitationsApplier.getAdditionalPermittedAddons(previousProductProductData);

        if (
            !hasSpecificAddOnBindings &&
            previousProductProductData.deviceType !== subProduct.productData.deviceType &&
            !additionalPermittedAddons.includes(subProduct.productData.partNo)
        ) {
            subProductWarnings.push(ActiveProjectsFactory.getWarning(
                WarningType.error,
                I18n.t('limitationRequiresHostModule4'),
            ));

            return true;
        }

        return false;
    };
}

export default limitationRequiresHostModule;
