//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
// 
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
// 
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import _                 from 'lodash';

import IllegalCharacters from '@constants/IllegalCharacters';

class String {
    static removeIllegalCharacters(value) {
        for (const illegalCharacter of IllegalCharacters) {
            const regularExpression = new RegExp(illegalCharacter, 'gis');
            value                   = value.replace(regularExpression, '');
        }

        return value;
    }

    static replaceOnIndex(value, index, replacement) {
        const stringParts  = _.split(value, '');
        stringParts[index] = replacement;

        return stringParts.join('');
    }
}

export default String;
